export const handleDownload = (items, fileName) => {
  const data = JSON.stringify(items, null, 2);
  const blob = new Blob([data], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(url);
};

export const handleImport = (event, items, setItems) => {
  const file = event.target.files[0];
  const reader = new FileReader();

  reader.onload = (e) => {
    try {
      const importedItems = JSON.parse(e.target.result);
      setItems([...items, ...importedItems]);
    } catch (error) {
      console.error("Error importing JSON file:", error);
    }
  };

  reader.readAsText(file);
};

export const handleDeleteItem = (index, items, setItems) => {
  const nextItems = [...items];
  nextItems.splice(index, 1);
  setItems(nextItems);
};
