import React, { useRef, useState } from "react";
import useFocus from "../utils/useFocus";
import { convertDataToList } from "../utils/listOperations";
import { handleDeleteItem, handleImport } from "../utils/fileOperations";
import EditButton from "../components/EditButton";
import DeleteButton from "../components/DeleteButton";

import JSZip from "jszip";
import { saveAs } from "file-saver";

const ArmaturesPage = () => {
  const [model, setModel] = useState("");
  const [voltage, setVoltage] = useState(12);
  const [power, setPower] = useState(1.1);
  const [oal, setOal] = useState(100);
  const [teeth, setTeeth] = useState(10);
  const [slots, setSlots] = useState(20);
  const [deShaftDiameter, setDeShaftDiameter] = useState(8);
  const [ceShaftDiameter, setCeShaftDiameter] = useState(10);
  const [od, setOd] = useState(50);
  const [manufacturer, setManufacturer] = useState("");
  const [manufacturerIds, setManufacturerIds] = useState("");
  const [ormeIds, setOrmeIds] = useState("");
  const [wai, setWai] = useState("");

  const [imagePaths, setImagePaths] = useState({});
  const fileInputRef = useRef(null);

  const [items, setItems] = useState([]);

  const inputRef = useFocus();

  const handleChangeModel = (event) => {
    setModel(event.target.value);
  };
  const handleChangeVoltage = (event) => {
    setVoltage(event.target.value);
  };
  const handleChangePower = (event) => {
    setPower(event.target.value);
  };
  const handleChangeOal = (event) => {
    setOal(event.target.value);
  };
  const handleChangeTeeth = (event) => {
    setTeeth(event.target.value);
  };
  const handleChangeSlots = (event) => {
    setSlots(event.target.value);
  };
  const handleChangeDeShaftDiameter = (event) => {
    setDeShaftDiameter(Number(event.target.value));
  };
  const handleChangeCeShaftDiameter = (event) => {
    setCeShaftDiameter(Number(event.target.value));
  };
  const handleChangeOd = (event) => {
    setOd(event.target.value);
  };
  const handleChangeManufacturerIds = (event) => {
    setManufacturerIds(event.target.value);
  };
  const handleChangeManufacturer = (event) => {
    setManufacturer(event.target.value);
  };
  const handleChangeOrmeIds = (event) => {
    setOrmeIds(event.target.value);
  };
  const handleChangeWai = (event) => {
    setWai(event.target.value);
  };

  const handleEditImage = (event, model) => {
    const file = event.target.files[0];

    if (file.name !== `${model}.png`) {
      resetFileInput();
    } else {
      setImagePaths({ ...imagePaths, [file.name]: file });
    }
  };
  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleDownload = () => {
    const zip = new JSZip();
    const updatedItems = items.map((item) => {
      return Object.keys(item).reduce((acc, key) => {
        if (item.hasOwnProperty("hasImage")) {
          item.hasImage = item.hasImage
            .replace("✅", true)
            .replace("❌", false);
        }
        acc[key] = item[key];
        return acc;
      }, {});
    });
    const jsonData = JSON.stringify(updatedItems, null, 2);
    zip.file("products-armatures.json", jsonData);
    const imagePromises = Object.entries(imagePaths).map(
      ([filename, file], index) => {
        const blob = new Blob([file]);
        zip.file(filename, blob);
        return Promise.resolve();
      },
    );

    Promise.all(imagePromises)
      .then(() => {
        zip.generateAsync({ type: "blob" }).then((content) => {
          saveAs(content, `armatures_data_${Date.now()}.zip`);
        });
      })
      .catch((error) => {
        console.error("Error adding image files to zip:", error);
      });
  };

  const handleAddItem = () => {
    const manufacturerIdsEntry = convertDataToList(manufacturerIds);
    const ormeIdsEntry = convertDataToList(ormeIds);
    const isImageSet = !!imagePaths[`${model}.png`];

    const newItem = {
      imageUrl: `/products/armatures/${model.trim()}.png`,
      hasImage: isImageSet ? "✅" : "❌",
      Model: model.trim(),
      Voltage: `${voltage}`,
      Power: `${power}`,
      OAL: oal,
      Teeth: teeth,
      Slots: slots,
      DEShaftDiameter: deShaftDiameter,
      CEShaftDiameter: ceShaftDiameter,
      OD: od,
      Manufacturer: manufacturer,
      ManufacturerIds: manufacturerIdsEntry,
      ORME: ormeIdsEntry,
      WAI: wai,
    };
    let newItems = [...items, newItem].sort((a, b) =>
      a.Model.localeCompare(b.Model),
    );
    setItems(newItems);

    setModel("");
    setVoltage(12);
    setPower(1.1);
    setOal(100);
    setTeeth(10);
    setSlots(20);
    setDeShaftDiameter(8);
    setCeShaftDiameter(10);
    setOd(50);
    setManufacturer("");
    setManufacturerIds("");
    setOrmeIds("");
    setWai("");
    resetFileInput();
  };

  const handleEditItem = (index) => {
    const updatedItems = [...items];
    const item = updatedItems[index];

    setModel(item.Model);
    setVoltage(item.Voltage);
    setPower(item.Power);
    setOal(item.OAL);
    setTeeth(item.Teeth);
    setSlots(item.Slots);
    setDeShaftDiameter(item.DEShaftDiameter);
    setCeShaftDiameter(item.CEShaftDiameter);
    setOd(item.OD);
    setManufacturer(item.Manufacturer);
    setManufacturerIds(item.ManufacturerIds);
    setOrmeIds(item.ORME);
    setWai(item.WAI);
    handleDeleteItem(index, items, setItems);
    const { [`${item.Model}.png`]: _, ...nextImagePaths } = imagePaths;
    setImagePaths(nextImagePaths);
  };

  return (
    <>
      <h1 className="text-center text-2xl py-4">Add Armatures</h1>
      <div className="pt-8">
        <div className="flex justify-center">
          <div className="w-full sm:w-2/3 md:w-2/3 lg:w-2/3 xl:w-2/3 p-4 border border-gray-300 rounded-lg shadow-md">
            <label>
              <div className="text-center">
                Model <span className="text-red-500">*</span>
              </div>
              <input
                type="text"
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                placeholder="BS001"
                value={model}
                onChange={handleChangeModel}
                ref={inputRef}
                onKeyDown={(event) => {
                  const key = event.key;
                  if (/^[,:"]$/.test(key)) {
                    event.preventDefault();
                  }
                }}
              />
            </label>
            <label>
              <div className="text-center">Voltage</div>
              <select
                value={voltage}
                onChange={handleChangeVoltage}
                className="w-full mb-4 p-2 border border-gray-300 rounded"
              >
                <option value={12}>12V</option>
                <option value={24}>24V</option>
              </select>
            </label>
            <label>
              <div className="text-center">Power: {power}kW</div>
              <input
                type="range"
                min={0.1}
                max={8.0}
                step={0.1}
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                value={power}
                onChange={handleChangePower}
              />
            </label>
            <label>
              <div className="text-center">OAL: {oal}</div>
              <input
                type="range"
                min={0.5}
                max={150.0}
                step={0.5}
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                value={oal}
                onChange={handleChangeOal}
              />
            </label>
            <label>
              <div className="text-center">Teeth: {teeth}</div>
              <input
                type="range"
                min={1}
                max={25}
                step={1}
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                value={teeth}
                onChange={handleChangeTeeth}
              />
            </label>
            <label>
              <div className="text-center">Slots: {slots}</div>
              <input
                type="range"
                min={1}
                max={50}
                step={1}
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                value={slots}
                onChange={handleChangeSlots}
              />
            </label>
            <label>
              <div className="text-center">DE Shaft Ø: {deShaftDiameter}</div>
              <input
                type="range"
                min={1}
                max={20}
                step={1}
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                value={deShaftDiameter}
                onChange={handleChangeDeShaftDiameter}
              />
            </label>
            <label>
              <div className="text-center">CE Shaft Ø: {ceShaftDiameter}</div>
              <input
                type="range"
                min={1}
                max={20}
                step={0.5}
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                value={ceShaftDiameter}
                onChange={handleChangeCeShaftDiameter}
              />
            </label>
            <label>
              <div className="text-center">OD: {od}</div>
              <input
                type="range"
                min={40}
                max={70}
                step={1}
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                value={od}
                onChange={handleChangeOd}
              />
            </label>
            <label>
              <div className="text-center">Manufacturer (single entry)</div>
              <input
                type="text"
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                placeholder="Bosch, Valeo, Denso, ..."
                value={manufacturer}
                onChange={handleChangeManufacturer}
                onKeyDown={(event) => {
                  const key = event.key;
                  if (/^[,:"]$/.test(key)) {
                    event.preventDefault();
                  }
                }}
              />
            </label>
            <label>
              <div className="text-center">Manufacturer ids (list)</div>
              <input
                type="text"
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                placeholder="12345N, 7890, SomeId"
                value={manufacturerIds}
                onChange={handleChangeManufacturerIds}
                onKeyDown={(event) => {
                  const key = event.key;
                  if (/^"$/.test(key)) {
                    event.preventDefault();
                  }
                }}
              />
            </label>
            <label>
              <div className="text-center">ORME ids (list)</div>
              <input
                type="text"
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                placeholder="IM3166, 1234, SomeId"
                value={ormeIds}
                onChange={handleChangeOrmeIds}
                onKeyDown={(event) => {
                  const key = event.key;
                  if (/^"$/.test(key)) {
                    event.preventDefault();
                  }
                }}
              />
            </label>
            <label>
              <div className="text-center">WAI</div>
              <input
                type="text"
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                placeholder="61-9157"
                value={wai}
                onChange={handleChangeWai}
                onKeyDown={(event) => {
                  const key = event.key;
                  if (/^[,:"]$/.test(key)) {
                    event.preventDefault();
                  }
                }}
              />
            </label>
            <label>
              <div className="text-center">Image</div>
              <input
                type="file"
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                accept="image/png"
                onChange={(event) => handleEditImage(event, model)}
                ref={fileInputRef}
              />
            </label>

            <div className="pt-2">
              <button
                disabled={!model}
                className={`w-full py-2 px-4 rounded mb-4 text-white ${
                  !model ? "bg-gray-500" : "bg-green-800"
                }`}
                onClick={handleAddItem}
              >
                Add
              </button>
              <button
                disabled={items.length === 0}
                className={`w-full py-2 px-4 rounded mb-4 text-white ${
                  items.length === 0 ? "bg-gray-500" : "bg-blue-800"
                }`}
                onClick={handleDownload}
              >
                Download
              </button>
              <input
                type="file"
                className="hidden"
                accept=".json"
                onChange={(event) => handleImport(event, items, setItems)}
                id="file-input"
              />
              <label
                htmlFor="file-input"
                className="w-full bg-orange-600 text-white py-2 px-4 rounded cursor-pointer mb-4 flex items-center justify-center"
              >
                Import JSON
              </label>
            </div>
            <div className="mt-4">
              {items.map((item, index) => (
                <div
                  key={`${item.Model} ${item.Voltage} ${item.Power}`}
                  className="relative bg-gray-100 p-4 mb-2 rounded-lg shadow-md"
                >
                  <pre style={{ fontFamily: "monospace" }}>
                    {`${JSON.stringify(item, null, 2)}`}
                  </pre>
                  <EditButton handleEditItem={handleEditItem} index={index} />
                  <DeleteButton
                    handleDeleteItem={handleDeleteItem}
                    items={items}
                    setItems={setItems}
                    index={index}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArmaturesPage;
