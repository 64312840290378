import React, { useRef, useState } from "react";
import useFocus from "../utils/useFocus";
import {
  convertDataToList,
  getEntriesAsSingleString,
} from "../utils/listOperations";
import { handleDeleteItem, handleImport } from "../utils/fileOperations";
import EditButton from "../components/EditButton";
import DeleteButton from "../components/DeleteButton";

import JSZip from "jszip";
import { saveAs } from "file-saver";

const StartersPage = () => {
  const [model, setModel] = useState("");
  const [voltage, setVoltage] = useState(12);
  const [power, setPower] = useState(1.1);
  const [oem, setOem] = useState("");
  const [oemIds, setOemIds] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [manufacturerIds, setManufacturerIds] = useState("");
  const [lesterIds, setLesterIds] = useState("");
  const [applications, setApplications] = useState("");
  const [imagePaths, setImagePaths] = useState({});
  const fileInputRef = useRef(null);

  const [items, setItems] = useState([]);

  const inputRef = useFocus();

  const handleChangeModel = (event) => {
    setModel(event.target.value);
  };
  const handleChangeVoltage = (event) => {
    setVoltage(event.target.value);
  };
  const handleChangePower = (event) => {
    setPower(event.target.value);
  };
  const handleChangeOem = (event) => {
    setOem(event.target.value);
  };
  const handleChangeOemIds = (event) => {
    setOemIds(event.target.value);
  };
  const handleChangeManufacturer = (event) => {
    setManufacturer(event.target.value);
  };
  const handleChangeManufacturerIds = (event) => {
    setManufacturerIds(event.target.value);
  };
  const handleChangeLesterIds = (event) => {
    setLesterIds(event.target.value);
  };
  const handleChangeApplication = (event) => {
    setApplications(event.target.value);
  };
  const handleEditImage = (event, model) => {
    const file = event.target.files[0];

    if (file.name !== `${model}.png`) {
      resetFileInput();
    } else {
      setImagePaths({ ...imagePaths, [file.name]: file });
    }
  };
  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleDownload = () => {
    const zip = new JSZip();
    const updatedItems = items.map((item) => {
      return Object.keys(item).reduce((acc, key) => {
        if (item.hasOwnProperty("hasImage")) {
          item.hasImage = item.hasImage
            .replace("✅", true)
            .replace("❌", false);
        }
        acc[key] = item[key];
        return acc;
      }, {});
    });
    const jsonData = JSON.stringify(updatedItems, null, 2);
    zip.file(`products-starters.json`, jsonData);
    const imagePromises = Object.entries(imagePaths).map(
      ([filename, file], index) => {
        const blob = new Blob([file]);
        zip.file(filename, blob);
        return Promise.resolve();
      },
    );

    Promise.all(imagePromises)
      .then(() => {
        zip.generateAsync({ type: "blob" }).then((content) => {
          saveAs(content, `starter_data_${Date.now()}.zip`);
        });
      })
      .catch((error) => {
        console.error("Error adding image files to zip:", error);
      });
  };

  const handleAddItem = () => {
    let oemIdList = oemIds ? oemIds : [];
    const oemEntry = oem ? [`${oem}: ${oemIds}`] : oemIdList;

    const manufacturerIdsEntry = convertDataToList(manufacturerIds);
    const lesterIdsEntry = convertDataToList(lesterIds);
    const applicationEntry = convertDataToList(applications);
    const isImageSet = !!imagePaths[`${model}.png`];

    const newItem = {
      imageUrl: `/products/starters/${model.trim()}.png`,
      hasImage: isImageSet ? "✅" : "❌",
      Model: model.trim(),
      Voltage: `${voltage}`,
      Power: `${power}`,
      OEM: oemEntry,
      Manufacturer: manufacturer,
      ManufacturerIds: manufacturerIdsEntry,
      Lester: lesterIdsEntry,
      Application: applicationEntry,
    };
    let newItems = [...items, newItem].sort((a, b) =>
      a.Model.localeCompare(b.Model),
    );
    setItems(newItems);
    setModel("");
    setVoltage(12);
    setPower(1.1);
    setOem("");
    setOemIds("");
    setManufacturer("");
    setManufacturerIds("");
    setLesterIds("");
    setApplications("");
    resetFileInput();
  };

  const handleEditItem = (index) => {
    const updatedItems = [...items];
    const item = updatedItems[index];

    setModel(item.Model);
    setVoltage(Number(item.Voltage));
    setPower(Number(item.Power));
    if (item.OEM.length > 0) {
      const joinedOems = getEntriesAsSingleString(item.OEM);
      const oems = joinedOems.split(":");
      if (oems.length > 1) {
        setOem(oems[0].trim());
        setOemIds(oems[1].trim());
      } else {
        setOem("");
        setOemIds(oems[0].trim());
      }
    }

    setManufacturer(item.Manufacturer);
    setManufacturerIds(item.ManufacturerIds);
    setLesterIds(item.Lester);
    setApplications(item.Application);
    handleDeleteItem(index, items, setItems);
    const { [`${item.Model}.png`]: _, ...nextImagePaths } = imagePaths;
    setImagePaths(nextImagePaths);
  };

  return (
    <>
      <h1 className="text-center text-2xl py-4">Add Starter Engines</h1>
      <div className="pt-8">
        <div className="flex justify-center">
          <div className="w-full sm:w-2/3 md:w-2/3 lg:w-2/3 xl:w-2/3 p-4 border border-gray-300 rounded-lg shadow-md">
            <label>
              <div className="text-center">
                Model <span className="text-red-500">*</span>
              </div>
              <input
                type="text"
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                placeholder="BS001"
                value={model}
                onChange={handleChangeModel}
                ref={inputRef}
                onKeyDown={(event) => {
                  const key = event.key;
                  if (/^[,:"]$/.test(key)) {
                    event.preventDefault();
                  }
                }}
              />
            </label>
            <label>
              <div className="text-center">Voltage</div>
              <select
                value={voltage}
                onChange={handleChangeVoltage}
                className="w-full mb-4 p-2 border border-gray-300 rounded"
              >
                <option value={12}>12V</option>
                <option value={24}>24V</option>
              </select>
            </label>
            <label>
              <div className="text-center">Power: {power}kW</div>
              <input
                type="range"
                min={0.1}
                max={8.0}
                step={0.1}
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                value={power}
                onChange={handleChangePower}
              />
            </label>
            <label>
              <div className="text-center">OEM (single entry)</div>
              <input
                type="text"
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                placeholder="BMW, Ford, ... or empty"
                value={oem}
                onChange={handleChangeOem}
                onKeyDown={(event) => {
                  const key = event.key;
                  if (/^[,:"]$/.test(key)) {
                    event.preventDefault();
                  }
                }}
              />
            </label>

            <label>
              <div className="text-center">OEM ids (list)</div>
              <input
                type="text"
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                placeholder="12345N, 7890, SomeId"
                value={oemIds}
                onChange={handleChangeOemIds}
                onKeyDown={(event) => {
                  const key = event.key;
                  if (/^[:"]$/.test(key)) {
                    event.preventDefault();
                  }
                }}
              />
            </label>

            <label>
              <div className="text-center">Manufacturer (single entry)</div>
              <input
                type="text"
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                placeholder="Bosch, Valeo, Denso, ..."
                value={manufacturer}
                onChange={handleChangeManufacturer}
                onKeyDown={(event) => {
                  const key = event.key;
                  if (/^[,:"]$/.test(key)) {
                    event.preventDefault();
                  }
                }}
              />
            </label>
            <label>
              <div className="text-center">Manufacturer ids (list)</div>
              <input
                type="text"
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                placeholder="12345N, 7890, SomeId"
                value={manufacturerIds}
                onChange={handleChangeManufacturerIds}
                onKeyDown={(event) => {
                  const key = event.key;
                  if (/^"$/.test(key)) {
                    event.preventDefault();
                  }
                }}
              />
            </label>

            <label>
              <div className="text-center">Lester ids (list)</div>
              <input
                type="text"
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                placeholder="12345N, 7890, SomeId"
                value={lesterIds}
                onChange={handleChangeLesterIds}
                onKeyDown={(event) => {
                  const key = event.key;
                  if (/^"$/.test(key)) {
                    event.preventDefault();
                  }
                }}
              />
            </label>

            <label>
              <div className="text-center">Applications (list)</div>
              <textarea
                className="w-full mb-4 p-2 border border-gray-300 rounded h-24"
                placeholder="Benz C200 E200 E260 1.8L 2009-2011, Ford Focus 1.8L 2011-2015"
                value={applications}
                onChange={handleChangeApplication}
                onKeyDown={(event) => {
                  const key = event.key;
                  if (/^"$/.test(key)) {
                    event.preventDefault();
                  }
                }}
              />
            </label>

            <label>
              <div className="text-center">Image</div>
              <input
                type="file"
                className="w-full mb-4 p-2 border border-gray-300 rounded"
                accept="image/png"
                onChange={(event) => handleEditImage(event, model)}
                ref={fileInputRef}
              />
            </label>

            <div className="pt-2">
              <button
                disabled={!model}
                className={`w-full py-2 px-4 rounded mb-4 text-white ${
                  !model ? "bg-gray-500" : "bg-green-800"
                }`}
                onClick={handleAddItem}
              >
                Add
              </button>
              <button
                disabled={items.length === 0}
                className={`w-full py-2 px-4 rounded mb-4 text-white ${
                  items.length === 0 ? "bg-gray-500" : "bg-blue-800"
                }`}
                onClick={handleDownload}
              >
                Download
              </button>
              <input
                type="file"
                className="hidden"
                accept=".json"
                onChange={(event) => handleImport(event, items, setItems)}
                id="file-input"
              />
              <label
                htmlFor="file-input"
                className="w-full bg-orange-600 text-white py-2 px-4 rounded cursor-pointer mb-4 flex items-center justify-center"
              >
                Import JSON
              </label>
            </div>

            <div className="mt-4">
              {items.map((item, index) => (
                <div
                  key={`${item.Model} ${item.Voltage} ${item.Power}`}
                  className="relative bg-gray-100 p-4 mb-2 rounded-lg shadow-md"
                >
                  <pre style={{ fontFamily: "monospace" }}>
                    {JSON.stringify(item, null, 2)}
                  </pre>
                  <EditButton handleEditItem={handleEditItem} index={index} />
                  <DeleteButton
                    handleDeleteItem={handleDeleteItem}
                    items={items}
                    setItems={setItems}
                    index={index}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StartersPage;
