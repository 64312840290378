import React from "react";
import EditIcon from "./EditIcon";

function EditButton({ handleEditItem, index }) {
  return (
    <button
      className="absolute top-2 right-2 flex items-center justify-center bg-blue-300 hover:bg-blue-500 py-2 px-2 rounded focus:outline-none focus:ring-2 focus:ring-red-500 duration-300 opacity-50"
      onClick={() => handleEditItem(index)}
    >
      <EditIcon className="w-4 h-4" />
    </button>
  );
}

export default EditButton;
