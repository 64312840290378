import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import StartersPage from "./pages/StartersPage";
import ArmaturesPage from "./pages/ArmaturesPage";

const AppRouter = () => (
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/starters" element={<StartersPage />} />
      <Route path="/armatures" element={<ArmaturesPage />} />
    </Routes>
  </Router>
);

export default AppRouter;
