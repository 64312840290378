export function getEntriesAsSingleString(ids) {
  let idsAsString;
  if (Array.isArray(ids)) {
    idsAsString = ids.join(", ");
  } else {
    idsAsString = ids;
  }
  // remove multiple consecutive and trailing commas
  return idsAsString.replace(/,{2,}/g, ",").replace(/,+$/, "");
}

export function getEntriesAsList(inputString) {
  return inputString !== undefined && inputString !== ""
    ? inputString
        .split(",")
        .map((item) => item.trim())
        .filter(Boolean)
    : [];
}

export function convertDataToList(ids) {
  return getEntriesAsList(getEntriesAsSingleString(ids));
}
