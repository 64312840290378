import React from "react";
import BinIcon from "./BinIcon";

function DeleteButton({ handleDeleteItem, index, items, setItems }) {
  return (
    <button
      className="absolute top-12 right-2 flex items-center justify-center bg-red-300 hover:bg-red-500 py-2 px-2 rounded focus:outline-none focus:ring-2 focus:ring-red-500 duration-300 opacity-50"
      onClick={() => handleDeleteItem(index, items, setItems)}
    >
      <BinIcon className="w-4 h-4" />
    </button>
  );
}

export default DeleteButton;
