import { Link } from "react-router-dom";

const App = () => {
  const products = [
    { title: "Starters", link: "/starters" },
    { title: "Armatures", link: "/armatures" },
  ];
  return (
    <>
      <h1 className="text-center text-2xl py-4">Pick your product</h1>
      <div className="flex justify-center pt-8">
        <ul className="space-y-4">
          {products.map((product, index) => {
            return (
              <Link
                to={product.link}
                className="
                text-center
                block
                bg-zinc-100
                rounded-md
                shadow-md
                p-4
                hover:bg-zinc-200
                hover:shadow-xl
                transition-colors
                "
                key={index}
              >
                {product.title}
              </Link>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default App;
